<template>
  <router-view :key="this.forceReload"/>
</template>
<script>
import { getToken,onMessage } from "firebase/messaging";
import { messaging } from '@/firebase';
import axios from "axios";
import {Geolocation} from "@capacitor/geolocation";
import NoSleep from "nosleep.js";
import {Notify} from "quasar";
export default {
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    return{
    }
  },
  mounted(){
    this.setupPage();

  },
  computed:{
    token(){
      return this.$store.getters.token;
    }
  },
  methods: {
    setupPage(){
      if( this.token ) {
        this.enableNotifications();
        if (Geolocation.checkPermissions()){
          console.log("Geolocation granted");
        }else {
          Geolocation.requestPermissions();
        }
      }
    },
    registerToken(token) {
      if( this.token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
      }
      axios.post(
          this.$store.getters.url+"/api/api-web-notification-token",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    enableNotifications() {
      if (!("Notification" in window)) {
        alert("Notifications are not supported");
      } else if (Notification.permission === "granted") {
        this.initializeFirebase();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.initializeFirebase();
          } else {
            alert("No permission to send notification");
          }
        });
      } else {
        alert("No permission to send notification");
      }
    },
    initializeFirebase() {
        getToken(messaging, { vapidKey: 'BFWaFqmCXc8Wi6AoTjFYidbTFgeZvjg6BzXkKhkcogqnXR6LZhUKWV-b0D6xZqHAeguJDKcr2dwaLBfr4ZEp7MU' })
            .then((currentToken) => {
              if (currentToken) {
                this.registerToken(currentToken);
                // Send this token to your server and store it
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
        onMessage(messaging, (payload) => {
          Notify.create({
            color: 'green-5',
            textColor: 'white',
            icon: 'check',
            message:  payload.notification.body,
            timeout: 10000,
          })
          this.playSound();
          this.userInput = payload.notification.title + ',' + payload.notification.body;
          setTimeout(() => this.speakMessage(), 3500);
        });
    },
    playSound () {
      var data = { soundurl : 'https://api.blackwidow.org.za/sos.mp3'}
      var audio = new Audio(data.soundurl);
      audio.play();
    },
    speakMessage() {
      if (this.synth.speaking) {
        console.error('speechSynthesis.speaking');
        return;
      }
      if (this.userInput !== '') {
        this.validation = false;
        let sInstance = new SpeechSynthesisUtterance(this.userInput);
        // eslint-disable-next-line no-unused-vars
        sInstance.onend = function (event) {
          console.log('SpeechSynthesisUtterance.onend');
        }
        // eslint-disable-next-line no-unused-vars
        sInstance.onerror = function (event) {
          console.error(event);
        }
        sInstance.pitch = this.pitch;
        sInstance.rate = this.rate;
        this.synth.speak(sInstance);
      } else {
        this.validation = true;
      }
      this.userInput = '';
    },

  },
  data(){
    return {
      firebaseStarted : "false",
      forceReload : 1,
      userInput: '',
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,
      validation: false
    }
  }
};
</script>