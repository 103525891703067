import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from "axios";
import store from "@/stores/appStore";


store.dispatch('initializeStore');

const firebaseConfig = {
    apiKey: "AIzaSyACAM_mb5hzOZVt3BfGSaHVGTCR1XV8Qik",
    authDomain: "blackwidow-cms-demo.firebaseapp.com",
    projectId: "blackwidow-cms-demo",
    storageBucket: "blackwidow-cms-demo.appspot.com",
    messagingSenderId: "365408920007",
    appId: "1:365408920007:web:7d91725e32354a5d8c39e2"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request permission to send notifications
Notification.requestPermission()
    .then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get registration token for this device
            return getToken(messaging, { vapidKey: process.env.VUE_APP_vapid_Key });
        } else {
            console.log('Unable to get permission to notify.');
        }
    })
    .then((token) => {
        if (token) {
            let user_token = store.getters.token;
            console.log('Token received:', token);
            if( user_token ){
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + user_token;
            }
            axios.post(
                this.$store.getters.url+"/api/api-web-notification-token",
                {
                    token: token
                },
                {
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json"
                    }
                }
            ).then(() => {
            })
        }
    })
    .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
    });

// Foreground message handler
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Display notification or update UI accordingly
});